.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  height: 100%;
  overflow: hidden;
}

body,
#root,
.App {
  height: 100%;
}

/* Tamaño del scroll */
.boxForm::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.boxForm::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.boxForm {
  max-height: 60%;
  overflow: auto;
}

.react-datepicker__current-month {
  display: none;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 0px !important;
  font-size: 13px !important;
}

.react-datepicker__triangle {
  left: -7px !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .ghost {
    display: none;
  }
}

.no-information-container {
  height: 400px;
  width: 100%;
}
.no-information-message {
  height: 400px;
  min-width: 100%;
  text-align: center;
  position: absolute;
}

.modal-box .icon-close-modal {
  position: absolute;
  top: 13.75px;
  right: 22px;
  font-size: 20px;
  cursor: pointer;
}

.modal-box select,
.modal-box textarea,
.modal-box input {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(52, 60, 70, 0.14),
    0px 6px 30px rgba(52, 60, 70, 0.12), 0px 8px 10px rgba(52, 60, 70, 0.2);
  border-radius: 8px;
}

.modal-box textarea,
.modal-box input {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.modal-box .buttonsInputFields {
  width: 100%;
  margin-top: 50px;
  display: flex;
}

#id_steper {
  margin-top: 85px;
}

.buttonsStepsDiv,
#id_formContainer {
  background-color: #f8f9fa;
}

#id_formContainer {
  border-radius: 20px 20px 0 0;
  padding: 50px 45px;
}
.buttonsStepsContainer {
  position: absolute;
  top: 60%;
  padding: 0 !important;
}
.buttonsStepsDiv {
  justify-items: end;
  padding: 15px 65px;
  border-radius: 0 0 20px 20px;
  display: flex;
}
.loading {
  position: absolute;
  top: 25%;
  left: calc(50% - 75px);
}

.loadingText {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #003878;
  top: 25px;
  right: -30px;
  color: #003878;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.loadingText::before,
.loadingText::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.loadingText::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #003878;
  color: #003878;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.loadingText::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #003878;
  color: #003878;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dotFlashing {
  0% {
    background-color: #003878;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
